import http from '@/utils/http';

// 获取国家/地区
export const getAreaList = (params) => {
    return http.request({
        url: "/v1/olympics/area/list",
        method: 'get',
        params: params,
    })
}

// 更新国家/地区
export const updateArea = (params) => {
    return http.request({
        url: "/v1/olympics/area/update",
        method: 'post',
        data: params,
    })
}

// 搜索 国家/地区
export const searchArea = (params) => {
    return http.request({
        url: "/v1/olympics/area/search",
        method: 'post',
        data: params,
    })
}
